import React from 'react';
import { Container } from '../../../core';
import Image from 'next/image';
import { type Video } from '@ui/providers/static-content-provider';
import HomeHeroSection, { type HomeHeroSectionProps } from './HomeHeroSection';
import cn from '@ui/utils/cn';

type HomeHeroWithVideoSectionProps = HomeHeroSectionProps & {
  desktopVideo: Video;
  mobileVideo: Video;
};

const HomeHeroWithVideoSection: React.FC<HomeHeroWithVideoSectionProps> = ({
  url,
  desktopImage,
  mobileImage,
  desktopVideo,
  mobileVideo,
}) => {

  return (
    <div className="relative block w-full">
      <HomeHeroSection url={url} desktopImage={desktopImage} mobileImage={mobileImage} />
      <div className={cn(!mobileVideo.src ? "flex" :"hidden","absolute w-full h-[68%] left-0 md:left-unset md:w-unset md:h-full md:block md:inset-0 pointer-events-none bottom-[8%] md:bottom-unset")}>
        <Container className="md:static md:py-5 md:px-5 md:py-16 w-full md:h-full max-w-full">
          <video
            autoPlay
            className="h-full w-full md:w-auto"
            loop
            muted
            playsInline
            poster={desktopVideo.poster.src}
            preload="metadata"
          >
            <source src={desktopVideo.src} type="video/mp4" />
          </video>
        </Container>
      </div>
      {mobileVideo.src && 
      <div className="md:hidden absolute left-0 bottom-[8%] pointer-events-none flex items-end w-full h-full">
        <video
          autoPlay
          className="w-full h-[68%]"
          loop
          muted
          playsInline
          poster={mobileVideo.poster.src || desktopVideo.poster.src}
          preload="metadata"
        >
          <source src={mobileVideo.src || desktopVideo.src} type="video/mp4" />
        </video>
      </div>}
    </div>
  );
};

export default HomeHeroWithVideoSection;
